import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { ErrorLogNotifier } from '@logging/error-log-and-notifier.service';

import { NoConnectionBannerComponent } from '@app/app-layout/banners/no-connection/no-connection-banner.component';
import { BannerService } from '@design/layout/banner-layout/banner.service';
import { LoggingService } from '@logging/logging.service';
import { CintraHttpErrorResponse } from '@logging/extended-http-client';
import { FullScreenSpinnerService } from '@design/spinners/fullscreen-spinner/fullscreen-spinner.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Top-level in-line replacement for the Angular {@link https://angular.io/api/core/ErrorHandler|ErrorHandler}
 */
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private router: Router
  ) {}

  private resetSpinner() {
    // ensure spinner is shut down
    const fullScreenSpinnerService = this.injector.get<FullScreenSpinnerService>(FullScreenSpinnerService);
    fullScreenSpinnerService.reset();
  }

  handleError(error: Error) {
    const errorLogNotifier = this.injector.get(ErrorLogNotifier);
    const bannerService = this.injector.get(BannerService);

    this.resetSpinner();

    if (error instanceof HttpErrorResponse) {
      if ([0, 777].includes(error.status)) {
        /**
         * Angular sets status = 0 when a network request was unable to complete...
         * Cypress: "An invalid StaticResponse was supplied to cy.intercept(). statusCode must be a number between 100 and 999"
         * so, e2e stubs with code 777...
         * */

        bannerService.show('no-connection', NoConnectionBannerComponent);

        return;
      }

      if (error.status === 402) {
        return this.router.navigateByUrl('locked').then();
      }

      const logger = this.injector.get(LoggingService);

      const message = error.message ?? `Status: ${error.status} | Url: ${error.url}`;

      const errorToLog = new Error(message);
      errorToLog.name = 'CintraCloudHttpError';

      const logExtras = {
        status: error.status,
        statusText: error.statusText,
        url: error.url,
        requestId: logger.requestId
      };

      console.error(error);

      errorLogNotifier.logAndNotify(errorToLog, logExtras);

      return;
    }

    if (error.message.includes('ChunkLoadError')) {
      // ETN-230 - network down during app load...
      bannerService.show('no-connection', NoConnectionBannerComponent);

      return;
    }

    errorLogNotifier.logAndNotify(error);
  }
}
