import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';

import { OnscreenMessagingService } from '../pattern-library/onscreen-messaging/onscreen-messaging.service';
import { LoggingConfig, LoggingConfigInjectionToken } from './logging-config';

/**
 * Logging Module facilitating the passing of logging-and-error-handling configuration to shared modules
 */
@NgModule({
  imports: [
    CommonModule,
    /*
    the following is default configuration for NGXLogger which will be overwritten typically in the LoggingService constructor
    using values passed up to forRoot (see below)
     */
    LoggerModule.forRoot({
      serverLoggingUrl: '',
      level: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.OFF
    })
  ],
  providers: [NGXLogger, OnscreenMessagingService]
})
export class LoggingModule {
  /**
   * The means for a runtime app to pass in their logging-and-error-handling config to this shared-module
   */
  static forRoot(config: LoggingConfig): ModuleWithProviders<LoggingModule> {
    return {
      ngModule: LoggingModule,
      providers: [{ provide: LoggingConfigInjectionToken, useValue: config }]
    };
  }
}
