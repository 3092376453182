import { Injectable } from '@angular/core';

@Injectable()
export class UiVersionStateService {
  localUiVersion: string = '';
  currentUiVersion: string = '';

  constructor() {}

  isDifferent(): boolean {
    return this.currentUiVersion.length > 0 && this.localUiVersion.length > 0 && this.currentUiVersion !== this.localUiVersion;
  }

  setLocalVersion(version: string) {
    this.localUiVersion = version;
  }

  setCurrentVersion(version: string) {
    this.currentUiVersion = version;
  }
}
