import { environment } from '@env/environment';

export function initCookieYesFunction() {
  return () => {
    if (environment.cookiePolicyDisabled) {
      return;
    }

    const n = document.createElement('script');
    n.type = 'text/javascript';
    n.src = 'https://cdn-cookieyes.com/client_data/ceb442b1d9f2080d1293d4be/script.js';
    n.async = !0;
    const a = document.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(n, a);
  };
}
