import { ModuleRegistry } from '@ag-grid-community/core';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';

export const registerAgGridModules = () => {
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    GridChartsModule,
    ExcelExportModule,
    FiltersToolPanelModule,
    RangeSelectionModule,
    RowGroupingModule,
    SideBarModule,
    StatusBarModule,
    SetFilterModule,
    MenuModule,
    CsvExportModule,
    ClipboardModule,
    MultiFilterModule
  ]);
};
