import { Injectable } from '@angular/core';
import { BannerService } from '@design/layout/banner-layout/banner.service';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { AccountExpiringBannerComponent } from './account-expiring-banner.component';

@Injectable({ providedIn: 'root' })
export class AccountExpiringBannerService {
  constructor(
    private activeSecurity: ActiveSecurityContextStateService,
    private bannerService: BannerService
  ) {}

  initService() {
    this.activeSecurity.maxLoginDate$.subscribe((maxLoginDate) => {
      if (!!maxLoginDate) {
        this.bannerService.show('account-expiring', AccountExpiringBannerComponent);
        setTimeout(() => {
          this.bannerService.hide('account-expiring');
        }, 15000);
      }
    });
  }
}

export function setUpAccountExpiringBannerService(accountExpiringBannerService: AccountExpiringBannerService) {
  return () => {
    accountExpiringBannerService.initService();
  };
}
