import { Inject, Injectable } from '@angular/core';

import { DEFAULT_INTERRUPTSOURCES, Idle, LocalStorageExpiry } from '@ng-idle/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggingService } from '@logging/logging.service';
import { IdleActivityConfigInjectionToken } from './idle-activity-config-injection.token';
import { IdleUtils } from './idle-utils';
import { IdleActivityConfig } from './idle-activity-config';

@Injectable({ providedIn: 'root' })
export class IdleActivityService {
  private logsSubscription: Subscription | undefined;
  private timedOutSubject = new BehaviorSubject<boolean>(false);
  idleTimeout$ = this.timedOutSubject.asObservable();

  constructor(
    @Inject(IdleActivityConfigInjectionToken) private config: IdleActivityConfig,
    private idle: Idle,
    private idleExpiry: LocalStorageExpiry,
    private logger: LoggingService,
    private utils: IdleUtils
  ) {}

  initialise() {
    this.utils.trace('IDLE: service initialising');

    // buffer before watching
    this.idle.setIdle(3); // in seconds

    // countdown to timeout
    this.idle.setTimeout(this.config.idleTimeout); // seconds

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // give our subscribers a timeout observable
    this.idle.onTimeout.subscribe(() => {
      this.logger.info('IDLE: timed-out');
      this.stopMonitoring();
      this.timedOutSubject.next(true);
    });

    this.setupDebugLogging();

    this.utils.trace('IDLE: service ready');
  }

  startMonitoring() {
    this.utils.trace('IDLE: start monitoring');
    this.timedOutSubject.next(false);
    this.idle.watch();
  }

  stopMonitoring() {
    this.utils.trace('IDLE: stop monitoring');
    this.idle.stop();
    if (!this.logsSubscription) return;
    (this.logsSubscription as Subscription).unsubscribe();
  }

  private setupDebugLogging() {
    let idledFor: number;
    const logInterval = 60 * 1000; // ms
    const log$ = timer(0, logInterval).pipe(
      tap((index) => {
        if (index === 0) idledFor = 0;
        const minutesIdle = idledFor === 0 ? 0 : Math.floor(idledFor / 1000 / 60);
        const minutesToTimeout = this.config.idleTimeout / 60 - minutesIdle;
        this.utils.trace(
          `IDLE Report: ${minutesIdle} minutes of inactivity have elapsed. Idle timeout will occur in ${minutesToTimeout} minutes.`
        );
        idledFor += logInterval;
      })
    );

    this.idle.onIdleStart.subscribe(() => {
      this.utils.trace('IDLE: went idle');
      this.logsSubscription = log$.subscribe();
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.utils.trace('IDLE: became active');
      (this.logsSubscription as Subscription).unsubscribe();
    });
  }
}
