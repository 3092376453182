import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';
import { ActiveSecurityContextStateService } from '../active-security/active-security-context.state-service';

@Injectable()
export class PayrollAccessDeniedInterceptor implements HttpInterceptor {
  private alertVisible = false;
  constructor(
    private onscreenMessagingService: OnscreenMessagingService,
    private activeSecurity: ActiveSecurityContextStateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && error.error === 'Access to payroll is denied') {
          if (!this.alertVisible) {
            this.alertVisible = true;
            this.onscreenMessagingService.openAlertDialog({
              title: 'Access Denied',
              message: 'You are not permitted to access this payroll',
              action: () => {
                this.alertVisible = false;
                this.activeSecurity.navigateHome();
              }
            });
          }
          return EMPTY;
        }
        return throwError(() => error);
      })
    ) as Observable<HttpEvent<any>>;
  }
}
