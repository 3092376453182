import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ApplicationInitialisationService } from './application-initialisation.service';
import { AbandonmentConfig } from '@auth-n/abandonment/abandonment-config';
import { AbandonmentConfigInjectionToken } from '@auth-n/abandonment/abandonment-config-injection.token';
import { FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';

export function initialiseApplication(applicationInitialisationService: ApplicationInitialisationService): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      applicationInitialisationService.initialise();
      resolve();
    });
}

@NgModule({
  imports: [RouterModule]
})
export class ApplicationInitialisationModule {
  /**
   * Call this in your Core module to set up amplify-js with the supplied settings.
   */
  static forRoot(abandonmentConfig: AbandonmentConfig): ModuleWithProviders<ApplicationInitialisationModule> {
    return {
      ngModule: ApplicationInitialisationModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initialiseApplication,
          deps: [ApplicationInitialisationService, FeatureFlagsServiceInjectionToken],
          multi: true
        },
        {
          provide: AbandonmentConfigInjectionToken,
          useValue: abandonmentConfig
        }
      ]
    };
  }
}
