import { Inject, Injectable } from '@angular/core';

import { Idle } from '@ng-idle/core';

import { LoggingService } from '@logging/logging.service';
import { AbandonmentConfig } from '@auth-n/abandonment/abandonment-config';
import { AbandonmentConfigInjectionToken } from '@auth-n/abandonment/abandonment-config-injection.token';

@Injectable({ providedIn: 'root' })
export class AbandonmentService {
  private readonly storageKey = 'Abandonment:lastBecameActive';

  constructor(
    private idle: Idle,
    @Inject(AbandonmentConfigInjectionToken) private config: AbandonmentConfig,
    private logger: LoggingService
  ) {
    this.setupBrowseAwayData();
  }

  public isAbandoned(): boolean {
    if (!this.config.enabled) {
      return false;
    }
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData == null) {
      this.logger.trace('Abandonment:', 'No stored data found');
      return false;
    } else {
      const browseAway = JSON.parse(storedData) as BrowseAwayData;
      const now = Date.now();
      const delta = now - browseAway.timestamp;
      const deltaSeconds = delta / 1000; // epoch stored in milliseconds
      this.logger.trace(
        'Abandonment: difference between stored time nad now (seconds)',
        deltaSeconds,
        ' configured limit: ',
        this.config.abandonmentTimeout
      );
      return deltaSeconds > this.config.abandonmentTimeout;
    }
  }

  public setLastBecameActive() {
    localStorage.setItem(this.storageKey, JSON.stringify(new BrowseAwayData(Date.now())));
  }

  private setupBrowseAwayData() {
    this.idle.onIdleEnd.subscribe(() => {
      this.setLastBecameActive();
    });
  }
}

export class BrowseAwayData {
  constructor(public timestamp: number) {}
}
