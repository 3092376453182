import { Routes } from '@angular/router';
import { BureauAuthorisedRouteGuard } from '@security/authorization/bureau-authorised.route-guard';

// modal routes only work at the root level...

export const bureauModalRoutes: Routes = [
  {
    canActivate: [BureauAuthorisedRouteGuard],
    data: {
      anyBureauProfilePermissions: ['EditBureauOrganisations'],
      anyApplicationPermissions: ['EditBureaux']
    },
    path: 'bureau/organisation/edit',
    outlet: 'modal',
    loadChildren: () =>
      import('./organisations/bureau-organisations-grid/bureau-organisation-edit-settings/bureau-organisation-edit-settings.module').then(
        (m) => m.BureauOrganisationEditSettingsModule
      )
  },
  {
    canActivate: [BureauAuthorisedRouteGuard],
    data: {
      anyBureauProfilePermissions: ['EditBureauOrganisations'],
      anyApplicationPermissions: ['EditBureaux']
    },
    path: 'bureau/organisation/payment-settings/edit',
    outlet: 'modal',
    loadChildren: () =>
      import(
        './organisations/bureau-organisations-grid/bureau-organisation-edit-payment-settings/bureau-organisation-edit-payment-settings.module'
      ).then((m) => m.BureauOrganisationEditPaymentSettingsModule)
  },
  {
    canActivate: [BureauAuthorisedRouteGuard],
    data: {
      anyBureauProfilePermissions: ['EditBureauOrganisations'],
      anyApplicationPermissions: ['EditBureaux']
    },
    path: 'bureau/organisation/payment-details',
    outlet: 'modal',
    loadChildren: () =>
      import('./organisations/bureau-organisations-grid/bureau-organisation-edit-settings/bureau-organisation-edit-settings.module').then(
        (m) => m.BureauOrganisationEditSettingsModule
      )
  },
  {
    canActivate: [BureauAuthorisedRouteGuard],
    data: {
      anyBureauProfilePermissions: ['EditBureauTeams'],
      anyApplicationPermissions: ['EditBureaux'],
      method: 'create'
    },
    path: 'bureau/team/create',
    outlet: 'modal',
    loadChildren: () => import('./teams/edit-modal/team-edit-modal.module').then((m) => m.TeamEditModalModule)
  },
  {
    canActivate: [BureauAuthorisedRouteGuard],
    data: {
      anyBureauProfilePermissions: ['EditBureauTeams'],
      anyApplicationPermissions: ['EditBureaux'],
      method: 'create-sub-team',
      showAsWide: true,
      modalType: 'Standard'
    },
    path: 'bureau/team/:id/create-sub-team',
    outlet: 'modal',
    loadChildren: () => import('./teams/edit-modal/team-edit-modal.module').then((m) => m.TeamEditModalModule)
  },
  {
    canActivate: [BureauAuthorisedRouteGuard],
    data: {
      anyBureauProfilePermissions: ['EditBureauTeams'],
      anyApplicationPermissions: ['EditBureaux'],
      method: 'edit',
      showAsWide: true,
      modalType: 'Standard'
    },
    path: 'bureau/team/:id',
    outlet: 'modal',
    loadChildren: () => import('./teams/edit-modal/team-edit-modal.module').then((m) => m.TeamEditModalModule)
  },
  {
    canActivate: [BureauAuthorisedRouteGuard],
    data: {
      anyBureauProfilePermissions: ['ViewBureauReports']
    },
    path: 'bureau/view-custom-report/:id',
    outlet: 'modal',
    loadChildren: () => import('./custom-reports/bureau-report/bureau-report-modal.module').then((m) => m.BureauViewCustomReportModule)
  },
  {
    canActivate: [BureauAuthorisedRouteGuard],
    data: {
      anyBureauProfilePermissions: ['AccessBACS']
    },
    path: 'bureau/bank-files/:id/details/:paymentRunNo',
    outlet: 'modal',
    loadChildren: () =>
      import('./bank-files/entity-grid/bureau-bank-files-details/bureau-bank-files-details.module').then(
        (m) => m.BureauBankFilesDetailsModule
      )
  }
];
