import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';
import { environment } from '@env/environment';
import { filter, take } from 'rxjs/operators';
import { AuthenticationService } from '@auth-n/authentication.service';

declare var window: any;

/**
 * Nothing more than a router outlet for the {@link: AppRoutingModule}.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  activeIdentity$ = this.activeAuthentication.activeIdentity$;
  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private ngZone: NgZone,
    private activeAuthentication: ActiveAuthenticationStateService,
    private authenticationService: AuthenticationService
  ) {
    this.matIconRegistry.addSvgIcon(
      'pound-sign',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../framework/design-system/assets/images/currency-gbp.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'file-pack',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../framework/design-system/assets/images/pack-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'download-bank-file',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../framework/design-system/assets/images/download-bank-file.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'download-bank-file-batch',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../framework/design-system/assets/images/download-bank-file-batch.svg')
    );

    if (environment.environment === 'e2e' || environment.environment === 'ci' || environment.environment === 'local')
      this.createTestSupport(this.ngZone, this.router);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1)
      )
      .subscribe((event: NavigationStart) => {
        this.authenticationService.setInitialUrl(event.url);
      });
  }

  private createTestSupport(ngZone: NgZone, router: Router) {
    // see also: e2e/cypress/support/ng-route-to.ts
    window.ngNavigateByUrl = function (url: string) {
      return ngZone.run(function () {
        return router.navigateByUrl(url).then();
      });
    };
  }
}
