import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';
import { akitaDevtools } from '@datorama/akita';
import { environment } from '@env/environment';
import { AppModule } from '@app/app.module';
import { registerAgGridModules } from '@app/advanced-reports/ag-grid-module-register';

registerAgGridModules();

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    if (!environment.production) {
      akitaDevtools();
      devTools({
        name: '@ngneat/elf',
        // assume the following is necessary with Angular - see https://ngneat.github.io/elf/docs/dev-tools/
        postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick()
      });
    }
  })
  .catch((err) => console.error(err));
