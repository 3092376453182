import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { HashMap } from '@utils/hash-map';
import { User as ConfigCatUser } from 'configcat-common';
import { IConfigCatClient } from 'configcat-common/lib/ConfigCatClient';
import * as configcat from 'configcat-js';
import { lastValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { environment } from '@env/environment';
import { FeatureFlagKey, FeatureFlagService, FeatureFlagType } from '../feature-flag.service';

interface FeatureFlagStore {
  initialised: boolean;
  allValues: HashMap<FeatureFlagType>;
}

@Injectable({ providedIn: 'root' })
export class ConfigCatFeatureFlagService implements FeatureFlagService, OnDestroy {
  private readonly store = createStore(
    { name: 'FeatureFlags' },
    withProps<FeatureFlagStore>({
      initialised: false,
      allValues: {}
    })
  );

  private configCatClient: IConfigCatClient = configcat.getClient(environment.configCatSettings.apiKey, configcat.PollingMode.AutoPoll, {
    pollIntervalSeconds: environment.configCatSettings.pollIntervalSeconds,
    setupHooks: (hooks) =>
      hooks.on('configChanged', () => {
        this.fetchValues();
      }),
    logger: configcat.createConsoleLogger(environment.configCatSettings.logLevel)
  });

  constructor(private activeSecurity: ActiveSecurityContextStateService) {
    // always load new values first time
    this.configCatClient.forceRefreshAsync().then(() => {
      // Fetch values early before the user context is known, because some values are read early (e.g. underMaintenance / currentUiVersion).
      this.fetchValues();

      this.activeSecurity.activeMembership$.pipe(take(1)).subscribe((x) => {
        // re-fetch values once the user context is known
        this.fetchValues();
      });
    });
  }

  private fetchValues() {
    this.configCatClient.getAllValuesAsync(this.getUserContext()).then((settingKeyValues) => {
      const allValues: HashMap<FeatureFlagType> = {};

      settingKeyValues.forEach((settingKeyValue) => {
        allValues[settingKeyValue.settingKey] = settingKeyValue.settingValue;
      });

      this.store.update(setProps({ initialised: true, allValues }));
    });
  }

  private getUserContext(): ConfigCatUser {
    const userEmail = this.activeSecurity.activeUser?.email || 'NOT SET';
    const userDomain = userEmail.split('@').pop();

    return {
      identifier: this.activeSecurity.activeUser?.id,
      email: userEmail,
      custom: {
        domain: userDomain,
        OrganisationName: this.activeSecurity.activeMembership?.organisationName ?? 'NOT SET',
        BureauName: this.activeSecurity.activeMembership?.bureauProfile?.name ?? 'NOT SET'
      }
    };
  }

  getValue$(key: FeatureFlagKey, defaultValue: FeatureFlagType = false): Observable<FeatureFlagType> {
    return this.store.pipe(
      filter((s) => s.initialised),
      select((s) => s.allValues),
      map((allValues) => (allValues.hasOwnProperty(key) ? allValues[key] : defaultValue)),
      distinctUntilChanged()
    );
  }

  getValue(key: FeatureFlagKey, defaultValue: FeatureFlagType = false): Promise<FeatureFlagType> {
    return lastValueFrom(this.getValue$(key, defaultValue).pipe(take(1)));
  }

  getAllValues$(): Observable<HashMap<FeatureFlagType>> {
    return this.store.pipe(
      filter((s) => s.initialised),
      select((s) => s.allValues),
      distinctUntilChanged()
    );
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
