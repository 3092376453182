import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const customReportTypesModalRoutes: Routes = [
  {
    path: 'custom-reports/edit',
    outlet: 'modal',
    loadChildren: () => import('./edit-modal/edit-custom-report-modal.module').then((m) => m.EditCustomReportModalModule)
  }
];
