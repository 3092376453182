import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const integrationsModalRoutes: Routes = [
  {
    path: 'integrations/create',
    outlet: 'modal',
    loadChildren: () => import('./integrations/create-modal/create-integration-modal.module').then((m) => m.CreateIntegrationModalModule)
  },
  {
    path: 'integrations/create-app-client',
    outlet: 'modal',
    loadChildren: () => import('./app-clients/create-modal/create-app-client-modal.module').then((m) => m.CreateAppClientModalModule)
  },
  {
    path: 'integrations/edit',
    outlet: 'modal',
    loadChildren: () => import('./integrations/edit-modal/edit-integration-modal.module').then((m) => m.EditIntegrationModalModule)
  },
  {
    matcher: (url) => {
      return url.length > 1 && url[1].path.match(/.*intellihr-setup.*/gi) ? { consumed: url } : null;
    },
    outlet: 'modal',
    loadChildren: () =>
      import('./integrations/intelli-setup-modal/integrations-intelli-setup.module').then((m) => m.IntegrationsIntelliSetupModule)
  },
  {
    matcher: (url) => {
      return url.length > 1 && url[1].path.match(/.*export-data-options.*/gi) ? { consumed: url } : null;
    },
    outlet: 'modal',
    loadChildren: () =>
      import('./integrations/data-export-modal/integrations-data-export.module').then((m) => m.IntegrationsDataExportModule)
  },
  {
    matcher: (url) => {
      return url.length > 1 && url[1].path.match(/.*export-data-success.*/gi) ? { consumed: url } : null;
    },
    outlet: 'modal',
    loadChildren: () =>
      import('./integrations/data-export-modal/integrations-data-export.module').then((m) => m.IntegrationsDataExportModule)
  },
  {
    path: 'integrations/event-errors/:integrationId',
    outlet: 'modal',
    loadChildren: () => import('./integrations/handler-errors-modal/handler-errors-modal.module').then((m) => m.HandlerErrorsModalModule)
  },
  {
    path: 'integrations/mapping/:integrationId/:organisationId',
    outlet: 'modal',
    loadChildren: () => import('./integrations/mapping-modal/mapping-modal.module').then((m) => m.MappingModalModule)
  }
];
