import { Injectable, Injector } from '@angular/core';

import { Log } from './log';
import { LoggingService } from './logging.service';
import { OnscreenMessagingService } from '@pattern-library/onscreen-messaging/onscreen-messaging.service';

/**
 * Top-level in-line replacement for the Angular {@link https://angular.io/api/core/ErrorHandler|ErrorHandler}
 */
@Injectable({ providedIn: 'root' })
export class ErrorLogNotifier {
  constructor(private injector: Injector) {}

  logAndNotify(error: Error, logExtras?: Partial<Log>) {
    console.error(error);

    // log to rollbar
    const logger = this.injector.get(LoggingService);
    logger.error(error, logExtras);

    const messagingService = this.injector.get<OnscreenMessagingService>(OnscreenMessagingService);
    messagingService.warn('There has been an unexpected problem with the application. Please contact support if the problem persists.');
  }
}
