import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const adminHubModalRoutes: Routes = [
  {
    path: 'admin-hub/approved-holiday',
    outlet: 'modal',
    loadChildren: () => import('./holiday-approvals/approved/admin-approved-holiday-modal.module').then((m) => m.AdminApprovedHolidayModule)
  },
  {
    path: 'admin-hub/holiday-approvals/:holidayRequestId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/admin-hub/holiday-approvals/requested/admin-holiday-request-modal.module').then((m) => m.AdminHolidayRequestModalModule)
  },
  {
    path: 'admin-hub/holiday-approvals/:holidayRequestId/decline',
    outlet: 'modal',
    loadChildren: () =>
      import('./holiday-approvals/decline/admin-decline-holiday-request.module').then((m) => m.AdminDeclineHolidayRequestModule)
  },
  {
    path: 'admin-hub/absence-approvals/:absenceRequestId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/absence-request/absence-request-modal.module').then(
        (m) => m.AbsenceRequestModalModule
      )
  },
  {
    path: 'admin-hub/approved-absence',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/approved-absence/approver-approved-absence.module').then(
        (m) => m.ApproverApprovedAbsenceModule
      )
  },
  {
    path: 'admin-hub/absence-approvals/:absenceRequestId/decline',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/absence-request/decline/decline-absence-request.module').then(
        (m) => m.DeclineAbsenceRequestModule
      )
  },
  {
    path: 'approved-holiday',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/approved-holiday/approved-holiday.module').then(
        (m) => m.ApprovedHolidayModule
      )
  }
];
