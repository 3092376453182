import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Amplify } from 'aws-amplify';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../environments/environment';
import { LoggingService } from '@logging/logging.service';
import { AuthenticationService } from './authentication.service';
import { AbandonmentService } from '@auth-n/abandonment/abandonment.service';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { DOCUMENT } from '@angular/common';
import { UiVersionStateService } from '@security/authorization/ui-version.state-service';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class ApplicationInitialisationService {
  constructor(
    @Inject(FeatureFlagsServiceInjectionToken) private featureFlagService: FeatureFlagService,
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private abandonmentService: AbandonmentService,
    private uiVersionStateService: UiVersionStateService,
    private injector: Injector,
    private logger: LoggingService
  ) {
    logger.trace('ApplicationInitialisationService: constructing');
  }

  initialise = () => {
    this.configureAmplifyJS();
    this.setLocalUiVersion();

    this.featureFlagService
      .getValue$('underMaintenance')
      .pipe(untilDestroyed(this))
      .subscribe((flaggedUnderMaintenance) => {
        this.logger.trace('ApplicationInitialisationService: under maintenance?', flaggedUnderMaintenance);

        if (flaggedUnderMaintenance) {
          this.logger.trace('ApplicationInitialisationService: signing-out and redirecting');

          this.authenticationService.userSignOut();
          this.injector.get(Router).navigateByUrl('/(top:under-maintenance)').then();
        } else this.checkAuthentication();
      });

    this.featureFlagService
      .getValue$('currentUiVersion')
      .pipe(untilDestroyed(this))
      .subscribe((currentUiVersion) => {
        this.uiVersionStateService.setCurrentVersion(currentUiVersion as string);
      });
  };

  private setLocalUiVersion() {
    let comments = this.getHtmlComments();
    if (comments.length > 0) {
      this.uiVersionStateService.setLocalVersion(comments.pop());
    }
  }

  private getHtmlComments(): string[] {
    let comments: string[] = [];
    let curNode: Node;
    let iterator = this.document.createNodeIterator(this.document.getRootNode(), NodeFilter.SHOW_COMMENT);

    while ((curNode = iterator.nextNode())) comments.push(curNode.nodeValue.trim());

    return comments;
  }

  /**
   * configure the app authentication provider (AWS AmplifyJS)
   */
  private configureAmplifyJS = () => {
    Amplify.configure(environment.cognitoConfig);

    this.logger.trace('ApplicationInitialisationService: configured amplify', environment.cognitoConfig.Auth, Amplify.getConfig());
  };

  /**
   * Try and set up an authentic user from the security cache, or navigate to sign-in.
   */
  private checkAuthentication = () => {
    if (this.abandonmentService.isAbandoned()) {
      // If we intervene to sign the user out and reload the page - reset the timer to now to prevent a loop
      this.abandonmentService.setLastBecameActive();
      this.authenticationService.signOutAndRedirect();
    } else {
      this.authenticationService.checkForAuthenticatedUser();
    }
  };
}
