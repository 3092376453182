import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const employeeHubModalRoutes: Routes = [
  {
    path: 'holiday/request', // create
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/my-leave/holiday-modal/request/my-request-holiday-modal.module').then(
        (m) => m.MyRequestHolidayModalModule
      )
  },
  {
    path: 'holiday-request', // view
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/my-leave/holiday-modal/view/view-my-holiday.module').then((m) => m.ViewMyHolidayModule)
  },
  {
    path: 'approved-holiday',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/approved-holiday/approved-holiday.module').then(
        (m) => m.ApprovedHolidayModule
      )
  },
  {
    path: 'holiday-approvals/:holidayRequestId/decline',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/grid/decline-holiday/decline-holiday-request.module').then(
        (m) => m.DeclineHolidayRequestModule
      )
  },
  {
    path: 'holiday-approvals/:holidayRequestId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/holiday-request/holiday-request-modal.module').then(
        (m) => m.HolidayRequestModalModule
      )
  },
  {
    path: 'absence-approvals/:absenceRequestId/decline',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/absence-request/decline/decline-absence-request.module').then(
        (m) => m.DeclineAbsenceRequestModule
      )
  },
  {
    path: 'absence-approvals/:absenceRequestId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/absence-request/absence-request-modal.module').then(
        (m) => m.AbsenceRequestModalModule
      )
  },
  {
    path: 'absence/request', // create
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/general-absence/request/my-request-absence-modal.module').then(
        (m) => m.MyRequestAbsenceModalModule
      )
  },
  {
    path: 'absence-request', // view
    outlet: 'modal',
    loadChildren: () => import('./employee-leave/general-absence/view/view-my-absence.module').then((m) => m.ViewMyAbsenceModule)
  },
  {
    path: 'approved-absence',
    outlet: 'modal',
    loadChildren: () =>
      import('./employee-leave/general-absence/approved-absence/approved-absence.module').then((m) => m.ApprovedAbsenceModule)
  },
  {
    path: 'edit-my-profile/:recordType',
    outlet: 'modal',
    loadChildren: () => import('./my-profile/edit/edit-my-profile.module').then((m) => m.EditMyProfileModule)
  },
  {
    path: 'edit-approver-sickness/:sicknessId',
    outlet: 'modal',
    loadChildren: () =>
      import('@app/employee-hub/employee-leave/leave-approvals/approver-sickness/approver-sickness.module').then(
        (m) => m.ApproverSicknessModule
      )
  },
  {
    path: 'sickness-request',
    outlet: 'modal',
    loadChildren: () => import('./employee-leave/my-leave/sickness/my-sickness.module').then((m) => m.MySicknessModule)
  }
];
