import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { NgIdleModule } from '@ng-idle/core';

import { IdleActivityConfigInjectionToken } from './idle-activity-config-injection.token';
import { IdleStateChangeHandler } from './idle-state-change.handler';
import { IdleActivityService } from './idle-activity.service';
import { IdleActivityConfig } from './idle-activity-config';
import { IdleUtils } from './idle-utils';

export function monitorIdleActivity(
  idleActivityService: IdleActivityService,
  idleStateChangeHandler: IdleStateChangeHandler,
  idleUtils: IdleUtils
): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      idleUtils.trace('monitorIdleActivity fn: setup', idleActivityService, idleStateChangeHandler);
      idleActivityService.initialise();
      idleStateChangeHandler.configureIdleMonitoring();
      resolve();
    });
}

// @dynamic
@NgModule({
  imports: [NgIdleModule.forRoot()]
})
export class IdleActivityModule {
  static forRoot(config: IdleActivityConfig): ModuleWithProviders<IdleActivityModule> {
    return {
      ngModule: IdleActivityModule,
      providers: [
        {
          provide: IdleActivityConfigInjectionToken,
          useValue: config
        },
        {
          provide: APP_INITIALIZER,
          useFactory: monitorIdleActivity,
          deps: [IdleActivityService, IdleStateChangeHandler, IdleUtils],
          multi: true
        }
      ]
    };
  }
}
