import { Inject, Injectable } from '@angular/core';

import { skipWhile, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { IdleActivityService } from './idle-activity.service';
import { IdleActivityConfigInjectionToken } from './idle-activity-config-injection.token';
import { IdleUtils } from './idle-utils';
import { IdleActivityConfig } from './idle-activity-config';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '@logging/logging.service';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';

@Injectable({ providedIn: 'root' })
export class IdleStateChangeHandler {
  constructor(
    @Inject(IdleActivityConfigInjectionToken) private config: IdleActivityConfig,
    private logger: LoggingService,
    private utils: IdleUtils,
    private idleActivityService: IdleActivityService,
    private authenticationService: AuthenticationService,
    private authenticationState: ActiveAuthenticationStateService
  ) {}

  /**
   * watch for a change in authentication status to reset idle-monitoring
   */
  configureIdleMonitoring() {
    this.authenticationState.activeIdentity$.subscribe((identity) => {
      const authenticated = identity !== null;
      this.utils.trace('IdleStateChangeHandler: active identity changed to ', identity);
      if (authenticated) {
        this.monitorIdleActivity();
      } else {
        this.idleActivityService.stopMonitoring();
      }
    });

    this.utils.trace('IdleStateChangeHandler: configured.');
  }

  /**
   * Monitor the idle timeout, and sign-out on timeout
   */
  private monitorIdleActivity() {
    this.idleActivityService.startMonitoring();
    this.idleActivityService.idleTimeout$
      .pipe(
        skipWhile((hasTimedOut) => !hasTimedOut),
        take(1)
      )
      .subscribe(() => {
        this.utils.trace('IdleStateChangeHandler: idle time-out; signing-out.');
        this.authenticationService.signOutAndRedirect();
      });
  }
}
