import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core.module';

import { UiVersionStateService } from '@security/authorization/ui-version.state-service';
import { BrowserModule } from '@angular/platform-browser';
import { AppLayoutModule } from './app-layout/app-layout.module';

/**
 * Root app module which bootstraps the root component {@link: AppComponent},
 * and which presents the app layout via the {@link: AppLayoutModule} and {@link: AppRoutingModule}.
 */
@NgModule({
  declarations: [AppComponent],

  imports: [FormsModule, BrowserModule, CoreModule, AppLayoutModule, AppRoutingModule, HighlightModule],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'), // Optional, only if you want the line numbers
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml')
        }
      }
    },
    UiVersionStateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
