import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';

import { ActiveSecurityContextStateService } from '../active-security/active-security-context.state-service';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';
import { LoggingService } from '@logging/logging.service';

/**
 * Prevents users accessing views that are for unauthenticated users only e.g. the login form.
 * Redirects to the dashboard if accessed.
 */
@Injectable({ providedIn: 'root' })
export class AnonymousRouteGuard {
  constructor(
    private router: Router,
    private authenticationState: ActiveAuthenticationStateService,
    private activeSecurityState: ActiveSecurityContextStateService,
    private logger: LoggingService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.activeSecurityState.fetchingActiveUser$.pipe(
      skipWhile((transitioning) => transitioning !== false),
      take(1),
      map((transitioning) => {
        this.logger.trace('ANONYMOUS ROUTE GUARD: transitioning?', transitioning);
        this.logger.trace('ANONYMOUS ROUTE GUARD: isAuthenticated?', this.authenticationState.isAuthenticated);
        if (this.authenticationState.isAuthenticated) {
          this.activeSecurityState.redirectToBestRoute();
          return false;
        }
        return true;
      })
    );
  }
}
